<template>
  <div id="users">
    <div class="page-header d-flex align-items-center justify-content-between">
      <div class="flex justify-content-start align-items-center">
        <h3 class="page-header-title">Users</h3>
        <span class="subtitle d-none d-md-inline-block">
          Create and manage users accounts
        </span>
      </div>
      <div class="d-flex">
        <base-button-dropdown title="Download CSV" action="secondary" @click-btn="downloadCsvAction(false)" :disabled="ui.isExport"
                              dropdownName="export_button" :loading="ui.isExport" style="min-width: max-content"  >
          <template #body>
            <div class="col-12">
              <div class="row p-1 cursor-pointer">
                <div class="col-12 text-start my-auto" @click="downloadCsvAction(true)">
                  <span>Export with all states</span>
                </div>
              </div>
            </div>
          </template>
        </base-button-dropdown>
        <base-button title="Create User" action="primary" v-if="permissions['addUser']" @click-btn="modals.addUserModal = true" class="ms-2" />
      </div>
    </div>
    <div class="page-tabs">
      <ul class="tab-list">
        <li :class="{ active: tab === 'Active' }" @click="setActiveTab('Active')">Active</li>
        <li :class="{ active: tab === 'Inactive' }" @click="setActiveTab('Inactive')">Inactive</li>
      </ul>
    </div>

    <div class="page-content p-normal">
      <div v-if="isGranted('ROLE_CEO') || isGranted('ROLE_FEE_SPLIT') || permissions['ceoAssistant']" class="row">
        <div class="col-6">
          <div class="search-wrapper">
            <img alt="Icon search" src="@/assets/icons/icon-search.svg">
            <input v-model="query" aria-label="search" placeholder="Search" type="text" @input="search">
          </div>
        </div>
        <div class="col-6 d-flex justify-content-end">
          <div class="form-group">
            <multiselect id="fieldFilter"
                         v-model="filter"
                         :allow-empty="false"
                         :class="{selected: filter.length}"
                         :close-on-select="true" :options="positions"
                         :searchable="true"
                         :show-labels="false"
                         class="full-width filter-select"
                         label="name"
                         placeholder="All Roles"
                         track-by="id"
                         @select="setPosition">
            </multiselect>
          </div>
        </div>
      </div>

      <div v-if="ui.isActiveLoading || ui.isInActiveLoading || ui.isLoading" class="text-center mt-4">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-else>
        <div class="table">
          <table class="table">
            <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Branch</th>
              <th v-if="isGranted('ROLE_CEO')">Hired Date</th>
              <th v-if="isGranted('ROLE_CEO')">Hired by</th>
              <th v-if="isGranted('ROLE_CEO')">Balance</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="agents.length === 0">
              <td :colspan="isGranted('ROLE_CEO') ? 7 : 4">
                <div class="col-12 modal-alert modal-alert_advice text-center">
                  No Results Found
                </div>
              </td>
            </tr>
            <tr v-for="(agent, index) in agents" :key="index">
              <td v-if="agent.isGranted.view || isGranted('ROLE_FEE_SPLIT')">{{ paging.offset + index + 1 }}</td>
              <td v-if="agent.isGranted.view || isGranted('ROLE_FEE_SPLIT')">
                <div class="d-flex align-items-center">
                  <div class="user-avatar-block">
                    <v-avatar :size="52" :src="agentPhoto(agent.photo)" rounded/>
                    <div :class="[ agent.isOnline ? 'user-active' : 'user-unactive' ]"></div>
                  </div>
                  <div class="user-info">
                    <router-link :to="{ name: 'users_details', params: {id: agent.id} }" class="nav-link">
                      <div class="user-name">{{ agent.fullName }}</div>
                    </router-link>
                    <div class="user-status">{{ agent.position }}</div>
                  </div>
                </div>
              </td>
              <td v-if="agent.isGranted.view || isGranted('ROLE_FEE_SPLIT')">{{ agent.branchName }}</td>
              <td v-if="isGranted('ROLE_CEO') && agent.isGranted.view">{{ agent.hiredDate }}</td>
              <td v-if="isGranted('ROLE_CEO') && agent.isGranted.view">{{ agent.hiredBy }}</td>
              <td v-if="isGranted('ROLE_CEO') && agent.isGranted.view">{{ convertToCurrency(agent.balance) }}</td>
              <td v-if="agent.isGranted.view">
                <div :name="'agent' + index" class="btn-modal">
                  <img alt="More button" class="icon-more" src="@/assets/icons/icon-more.svg">
                </div>
                <tippy :to="'agent' + index"
                       animateFill="false"
                       boundary="window"
                       class="table-menu"
                       distance="2"
                       interactive="true"
                       placement="bottom-end"
                       theme="light"
                       trigger="click"
                       zIndex="1039">
                  <div class="table-menu-block">
                    <ul>
                      <li class="action-label">Actions</li>
                      <li v-if="permissions['bookkeper']" class="cursor-pointer">
                        <a :href="backendUrl('/mlo/account/agent/' + agent.id)">
                          <img alt="" src="@/assets/icons/icon-inactive.svg">
                          Transactions
                        </a>
                      </li>
                      <li
                        v-if="(tab === 'Active' && agent.isGranted['delete']) && (isGranted('ROLE_CEO') || permissions['ceoAssistant'])"
                        class="cursor-pointer">
                        <a href="#" @click="makeInactive(agent.id)">
                          <img alt="" src="@/assets/icons/icon-remove.svg">
                          Make Inactive
                        </a>
                      </li>
                      <li
                        v-if="(tab === 'Inactive' && agent.isGranted['delete']) && (isGranted('ROLE_CEO') || permissions['ceoAssistant'])"
                        class="cursor-pointer">
                        <a href="#" @click="makeActive(agent.id)">
                          <img alt="" src="@/assets/icons/icon-active.svg">
                          Make Active
                        </a>
                      </li>
                    </ul>
                  </div>
                </tippy>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <Paging v-if="isGranted('ROLE_CEO') || isGranted('ROLE_FEE_SPLIT') || permissions['ceoAssistant'] || isGranted('ROLE_ANALYST')"
                :page="currentPage"
                :paging="paging"
                @limitChanged="pagingUpdated"
                @pageChanged="pagingUpdated">
        </Paging>
      </div>
    </div>

    <transition mode="out-in" name="component-fade">
      <add-new-user v-if="modals.addUserModal === true"
                    @close="modals.addUserModal = false"
                    @success="loadData"/>
    </transition>

    <transition mode="out-in" name="component-fade">
      <edit-user-modal v-if="modals.editUserModal === true"
                       @close="modals.editUserModal = false"/>
    </transition>

  </div>
</template>
<script>
import ClickOutside from 'vue-click-outside';
import AddNewUser from "./modals/AddNewUser";
import Multiselect from 'vue-multiselect';
import EditUserModal from "./modals/EditUserModal";
import Paging from "@/components/Paging";
import debounce from "@/utils/debounce";
import moment from 'moment';
import BaseButton from '../../components/BaseButton.vue';
import BaseButtonDropdown from '../../components/BaseButtonDropdown.vue';

export default {
  name: "Users",
  components: {EditUserModal, AddNewUser, Multiselect, Paging, BaseButton, BaseButtonDropdown},
  directives: {ClickOutside},
  data() {
    return {
      permissions: [],
      agents: [],
      activeAgents: [],
      inactiveAgents: [],
      positions: [],
      selectedPosition: {},
      openSelect: false,
      query: null,
      tab: 'Active',
      showSelect: false,
      filter: '',
      ui: {
        isActiveLoading: false,
        isInActiveLoading: false,
        isLoading: false,
        isExport: false,
        filters: ['all roles'],
      },
      modals: {
        addUserModal: false,
        editUserModal: false
      },
      currentPage: 1,
      paging: {
        offset: 0,
        limit: 10,
        total: 0
      },
      search: debounce(() => {
        this.paging.offset = 0
        this.searchUser()
      }, 350)
    }
  },
  methods: {
    loadData() {
      if (this.tab === 'Active') {
        this.getActiveAgents()
      } else {
        this.getInactiveAgents()
      }
      this.searchUser()
    },
    downloadCsvAction(withStates = false) {
      this.ui.isExport = true
      const isActive = (this.tab === 'Active');
      this.$http.get('/api/v1/staff/export', {
          params: {
            isActive: isActive,
            withStates: withStates
          }
        })
        .then((res) => {
          let fileURL = window.URL.createObjectURL(new Blob([res.data]));
          let fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `${isActive ? 'active_' : 'inactive_'}employees_report_` + moment().format('YYYY') + '.csv');
          document.body.appendChild(fileLink);
          fileLink.click();
          fileLink.remove();
        })
        .catch((err) => {
          this.alertError(err.response.statusText)
        })
        .finally(() => {
          this.ui.isExport = false
        })
    },
    getActiveAgents() {
      this.ui.isActiveLoading = true;
      this.$http.get('/api/v1/staff/active')
        .then((res) => {
          this.permissions = res.data.permissions
          this.handlePositions(res.data.positions)
        })
        .catch((err) => {
          this.alertError(err.response.statusText)
        })
        .finally(() => {
          this.ui.isActiveLoading = false;
        })
    },
    getInactiveAgents() {
      this.ui.isInActiveLoading = true;
      this.$http.get('/api/v1/staff/inactive')
        .then((res) => {
          this.permissions = res.data.permissions
          this.handlePositions(res.data.positions)
        })
        .catch((err) => {
          this.alertError(err.response.statusText)
        })
        .finally(() => {
          this.ui.isInActiveLoading = false;
        })
    },
    handlePositions(positions) {
      let data = Object.values(positions)
      data.unshift({id: null, name: 'All Roles'})
      this.positions = data
    },
    setActiveTab(tabName) {
      this.tab = tabName
      this.loadData()
    },
    searchUser() {
      this.ui.isLoading = true;
      this.$http.get('/api/v1/staff/search', {
        params: {
          position: this.selectedPosition.id,
          query: this.query,
          isActive: (this.tab === 'Active'),
          offset: this.paging.offset,
          limit: this.paging.limit
        }
      })
        .then((res) => {
          this.agents = res.data.agents
          this.paging = res.data.paging
        })
        .catch((err) => {
          this.alertError(err.response.statusText)
        })
        .finally(() => {
          this.ui.isLoading = false;
        })
    },
    setPosition(option) {
      this.selectedPosition = option
      this.searchUser()
    },
    pagingUpdated(paging) {
      this.paging = paging;
      this.currentPage = paging.currentPage
      this.searchUser();
    },
    makeInactive(id) {
      this.ui.isLoading = true;
      this.$http
        .delete(`/api/v1/staff/delete/${id}`)
        .then(() => {
          this.agents = this.agents.filter((agent) => {
            return agent.id !== id
          })
          this.alertSuccess('Done')
        })
        .finally(() => {
          this.ui.isLoading = false;
        })
    },
    makeActive(id) {
      this.ui.isLoading = true;
      this.$http
        .delete(`/api/v1/staff/restore/${id}`)
        .then(() => {
          this.agents = this.agents.filter((agent) => {
            return agent.id !== id
          })
          this.alertSuccess('Done')
        })
        .finally(() => {
          this.ui.isLoading = false;
        })
    }
  },
  beforeMount() {
    this.setActiveTab('Active')
  }
}
</script>

<style lang="scss" scoped>
#users {
  .page-header {
    button {
      max-width: 140px;
      width: 100%;
      min-height: 36px;
      max-height: 36px;
    }
  }

  div.table {
    table {
      tbody {
        td {
          color: rgba(0, 0, 0, .7);
          font-size: 14px;
          letter-spacing: 0;
          line-height: 21px;

          a {
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }
}

.row::v-deep {
  .multiselect {
    min-height: 36px;
    max-height: 36px;
    min-width: 250px;
    width: fit-content;
    @media screen and (max-width: 568px) {
      max-width: 50%;
      min-width: 100%;
    }

    &.selected {
      .multiselect__tags {
        background-color: #F3F3F4;
      }

      .multiselect__single {
        background-color: #F3F3F4;
      }

      input {
        background-color: #F3F3F4;
      }
    }

    &.multiselect--active {
      .multiselect__tags {
        transition: all .3s;
        border: 1px solid rgba(38, 111, 229, 0.5);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__tags {
      min-height: 36px;
      max-height: 36px;
      padding: 6px 40px 0 8px;
      transition: all .3s;

      &:hover {
        border: 1px solid rgba(38, 111, 229, 0.2);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__select {
      min-height: 36px;
      max-height: 36px;

      &:before {
        top: 6%;
        position: relative;
        right: 0;
        color: #999;
        border: solid rgba(0, 0, 0, 0.46);
        margin-top: 4px;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 3px;
        content: "";
        transform: rotate(44deg);
      }
    }

    &__single {
      color: rgba(0, 0, 0, 0.46);
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }

    &__option {
      padding: 10px 12px;
      min-height: 36px;
      transition: all .3s;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }

      &--highlight {
        background: #f3f3f3;
        line-height: 20px;
        font-size: 14px;
        color: #000;
        @media screen and (max-width: 568px) {
          font-size: 9px;
        }
      }
    }
  }
}

table.table {
  tbody {
    tr {
      vertical-align: inherit;
      padding: 0;

      &:hover {
        .btn-modal {
          opacity: 1;
        }
      }

      td {
        padding-top: 15px;
        padding-bottom: 15px;
        color: rgba(0, 0, 0, .7);
        font-size: 14px;
        letter-spacing: 0;
        line-height: 21px;

        &:not(:first-child) {
          padding-left: 0;
        }

        .user-name {
          text-decoration: none;
          width: fit-content;
          &:after {
            display: block;
            content: '';
            width: 100%;
            height: 1px;
            background: black;
            transition: all .5s;
          }

          &:hover {
            &:after {
              width: 0;
            }
          }
        }
      }

      .btn-modal {
        opacity: 0;
        transition: all .3s;
        outline: none;

        &.tippy-active {
          opacity: 1;
          border-radius: 8px;
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
}

.ml-auto {
  margin-left: auto !important;
}
</style>
