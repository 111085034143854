<template>
  <div class="pagination">
    <span class="pagination-count">
      {{ paging.offset }} -
      {{
        paging.offset + paging.limit > paging.total
          ? paging.total
          : paging.offset + paging.limit
      }}
      of {{ paging.total }}
    </span>
    <multiselect
      v-model="paging.limit"
      :options="[10, 20, 30, 50]"
      :searchable="false"
      :close-on-select="true"
      :allow-empty="false"
      :show-labels="false"
      :tabindex="-1"
      placeholder="10"
      class="pagingSelect"
      @select="updatePageLimit"
    >
    </multiselect>
    <div class="d-flex ms-2">
      <button
        class="btn btn-control me-2"
        @click="updatePage(pagination.currentPage - 1)"
        :disabled="pagination.currentPage <= 1"
      >
        <img src="@/assets/icons/bold-icon previous.svg" alt="" />
      </button>
      <button
        class="btn btn-control"
        @click="updatePage(pagination.currentPage + 1)"
        :disabled="paging.total < paging.offset + paging.limit"
      >
        <img src="@/assets/icons/bold-icon next.svg" alt="" />
      </button>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "Paging",
  components: { Multiselect },
  props: {
    paging: { type: Object, required: true },
    page: { type: Number },
  },
  data() {
    return {
      pagination: {
        offset: this.paging.offset,
        limit: this.paging.limit,
        total: this.paging.total,
        currentPage: this.page || 1,
      },
    };
  },
  methods: {
    updatePage(currentPage) {
      this.pagination.currentPage = currentPage;
      this.pagination.offset =
        (this.pagination.currentPage - 1) * this.pagination.limit;
      this.$emit("pageChanged", this.pagination);
    },
    updatePageLimit(value) {
      this.pagination.limit = Number.parseInt(value);
      this.$emit("limitChanged", this.pagination);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .multiselect {
    &__single {
      width: fit-content;
    }

    &__select {
      padding: 0;
    }

    &__tags {
      padding: 3px 40px 0px 8px;
    }

    &__select {
      padding: 0;
      &:before {
        top: 20%;
        position: relative;
        right: 0;
        color: #999;
        border: solid rgba(0, 0, 0, 0.46);
        margin-top: 4px;
        border-width: 0px 1px 1px 0;
        display: inline-block;
        padding: 3px;
        content: "";
        transform: rotate(44deg);
      }
    }
  }
}
</style>
